@import 'colors';
.starColors {
  width: 40px;
  height: 40px;
  color: $primary-color;
  margin-right: 12px;
}

.starWhite {
  width: 40px;
  height: 40px;
  color: $very-light-grey;
  margin-right: 12px;
}
