.banner {
  display: flex;
  justify-content: center;
  position: relative;
  height: 223px;
  margin-left: 100px;
  margin-right: 100px;
  .banner-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    filter: brightness(0.8);
  }
  div {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    .banner-p {
      color: white;
      font-weight: 500;
      font-size: 48px;
      margin-left: 16px;
    }
  }
}

@media screen and (max-width: 768px) {
  .banner {
    height: 111px;
    margin-left: 20px;
    margin-right: 20px;
    .banner-img {
      width: 100%;
    }
  }
  .banner div .banner-p {
    font-size: 24px;
  }
}
