.carrousel {
  // width:100%;
  margin: 0 100px;
}

.carrousel_img {
  background-position: 25%;
  object-fit: cover;
  height: 25rem;
  position: relative;
  border-radius: 25px;
  // margin: 0 0;
  background-size: cover;
}

.slide-count {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

.icons_situation {
  display: flex;
  justify-content: space-between;
}
.left {
  position: absolute;
  color: white;
  font-size: 100px;
  left: 5px;
  top: 170px;
}

.right {
  position: absolute;
  color: white;
  font-size: 100px;
  right: 5px;
  top: 170px;
}

@media screen and (max-width: 768px) {
  .carrousel {
    // width:100%;
    margin: 0 20px;
    // height: 300px;
  }
  .carrousel_img {
    height: 300px;
    // margin: 0 20px;
  }

  .left,
  .right {
    top: 85px;
    width: 30px;
  }
}
