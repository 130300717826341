@import 'colors';

.host_rate {
  flex-direction: column;
  margin-bottom: 2rem;
}

.host {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0 24px;
}

.host_name {
  color: $primary-color;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  margin-right: 10px;
  text-align: right;
}

.host_picture {
  border-radius: 50%;
  height: 64px;
  width: 64px;
}

.lodge_container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin: 0 100px;
}

.housing_title {
  color: $primary-color;
  font-weight: 500;
}

.housing_location {
  color: $primary-color;
  font-size: 18px;
}

.tag_container {
  display: flex;
  flex-wrap: wrap;
}

.housing_tag {
  background-color: $primary-color;
  border-radius: 10px;
  color: white;
  margin-bottom: 10px;
  margin-right: 10px;
  min-width: 68px;
  padding: 3px 10px;
}

.description {
  margin-top: 150px;
}

@media screen and (max-width: 768px) {
  .host {
    margin-right: 20px;
    margin-left: 100px;
    justify-content: center;
    padding: 0;
    margin: 0;
  }

  .host_picture {
    width: 32px;
    height: 32px;
    border-radius: 110px;
  }

  .lodge_container {
    flex-direction: column;
    margin: 0px 20px;
  }
  .housing_title,
  .housing_location,
  .housing_tag {
    margin: 5px 0;
  }
  .host_rate {
    display: flex;
    margin-top: 0px;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: baseline;
  }
}
