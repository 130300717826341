.bg-container {
  height: 224px;
  margin-left: 100px;
  margin-right: 100px;
  .bg-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}

/* Mobile */
@media screen and (max-width: 768px) {
  .bg-container {
    margin-left: 20px;
    margin-right: 20px;
  }
}
